import imagesLoaded from 'imagesloaded';
import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';

import gsap from '../lib/gsap.min';
import ScrollTrigger from '../lib/ScrollTrigger.min';
import ScrollToPlugin from '../lib/ScrollToPlugin.min';
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

import Swiper from 'swiper/bundle';

export function main() {
	
var Module = {};
var body;

Module.Compornent = {
	mobileToggleAsset: false,
	checkClass: function($this){
		$this = null;
	}
};

(function($) {
Module.Events = (function (){
'use strict';

var OBSERVER = {
	handlers: [],
	observeEvents: function(target){
		this.handlers.push(target);
	},
	deleteListener: function(element,listener){
		element.off(listener);
		element = null;
		listener = null;
	},
	clearEvents: function(){
		$(window).off('scroll resize');
		cancelAnimationFrame(timeReq);
	}
};

var winH = $(window).height();
var winW = $(window).width();
var body = $('body');


function nav_show(){
	body.addClass('navopen');
	navlistAnime();
}
function nav_hide(){
	body.removeClass('navopen');
}
function navInit(){
	nav_current();
	$('#nav_btn').on('click', function(){
		if( body.hasClass('navopen') ){
			nav_hide();
		}else{
			nav_show();
		}
	});
}

function navlistAnime (){
	let menuli = gsap.utils.toArray('#navul li');
	menuli.forEach((el, i) => {
		gsap.from(el, {y:-60,opacity:0, duration:0.3, delay:i*0.02+0.3})
	});
}
function postnavFunc(){
	const navh = document.getElementById('nav').clientHeight-8;
	let scrollTrigger = ScrollTrigger.create({
		trigger: '#top_post_cat',
		start: "top-="+navh+" top",
		onToggle: self => {
			if(self.isActive){
				document.body.classList.add('postnav_fix');
			}else{
				if(self.progress !== 1){
					document.body.classList.remove('postnav_fix');
				}
			}
		},
		onEnter: self => {
		},
		onUpdate: self => {
		}
	});
}

function nav_current(){
	document.querySelectorAll('#nav .catlist a').forEach(link => {
		link.classList.remove('active');
		const licat = link.dataset.pagename;
		const pagecatEl = document.querySelector('[data-barba-namespace]');
		const pagecat = pagecatEl.dataset.barbaNamespace;
		if(licat === pagecat){
			link.classList.add('active');
		}
	})
}

const setVH_Height = () => {
	const vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', vh+'px');
}
setVH_Height();

var navh;
const setVH = () => {
	const navvh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--kvvh', navvh+'px');
	const vw = window.innerWidth * 0.01;
	document.documentElement.style.setProperty('--vw', vw+'px');
	const navh = document.getElementById('nav').clientHeight;
	document.documentElement.style.setProperty('--navh', navh+'px');
}
window.addEventListener('resize', function () {
	if(window.matchMedia('(min-width:768px)').matches){
		setVH_Height();
	}
	setVH();
});


let EL_scrollTrigger;
function elFunc(EL){
	gsap.utils.toArray(EL).forEach(target => {
		if(window.matchMedia('(max-width:767px)').matches){
			if(target.classList.contains('nosp')){
				return;
			}
		}
		if(!target.classList.contains('nomove')){
			target.style.opacity = 0;
		}
		EL_scrollTrigger = ScrollTrigger.create({
			trigger: target,
			start: "top bottom",
			once: true,
			onEnter: self => {
				elAnime(target);
			},
		});
	});
	const navh = document.getElementById('nav').clientHeight;
	const up_scrollTrigger = ScrollTrigger.create({
		trigger: 'body',
		start: "top top",
		onUpdate: self => {
			const _slfscrl = self.scroll();
			if(self.direction == -1){
				document.body.classList.remove('scrldown');
			}else{
				if(_slfscrl > navh){
					if(self.prevSclrl < _slfscrl-10){
						document.body.classList.add('scrldown');
					}
					self.prevSclrl = _slfscrl;
				}
			}
			if(_slfscrl > winH){
				document.body.classList.add('onevh');
				document.getElementById('nav').classList.add('tnl');
			}else{
				document.body.classList.remove('onevh');
				document.getElementById('nav').classList.remove('tnl');
			}
		},
	});
}

function elAnime(target){
	target.style.opacity = 1;
	if(target.classList.contains('elp')){
		var elc = target.querySelectorAll('.elc');
	}else{
		var elc = [target];
	}
	elc.forEach((elc, i) => {
		let _y = 0;
		let _opa = 0;
		if(elc.classList.contains('nomove')){
			_y = 0;
			_opa = 1;
		}
		gsap.fromTo(elc,{y:_y,opacity:_opa},{
			y: 0,
			opacity:1,
			duration: 0.8,
			delay: i * 0.1,
			ease:'power1',
			onComplete: function(){
				elc.classList.add('elon');
			}
		});
	});
}

function scrlPos(){
	const maincontents = document.getElementById('maincontents');
	gsap.utils.toArray('.scrlpos').forEach(target => {
		const posname = target.dataset.pos;
		const scrl_scrollTrigger = ScrollTrigger.create({
			trigger: target,
			start: "top 50%",
			onEnter: self => {
				maincontents.classList.add(posname);
			},
			onLeaveBack: self => {
				maincontents.classList.remove(posname);
			}
		});
	});
}

async function ScrollSmootherKill() {
	await new Promise((resolve, reject) => {
		ScrollTrigger.killAll();
		resolve();
	})
}


function pageScroll(){
	let navelm = document.getElementById('nav');
	let scrltxtelm = document.getElementById('scrltxt');
	let tar_offset;
	if(scrltxtelm) {
		tar_offset = navelm.clientHeight + scrltxtelm.clientHeight;
	}else{
		tar_offset = navelm.clientHeight;
	}
	// if(window.matchMedia('(max-width:767px)').matches){
	// 	tar_offset = 0;
	// }
	$('a.anchor').on('click', function(){
		nav_hide();
		let href = this.hash;
		gsap.to(window, { duration:0.6, scrollTo:{y:href, autoKill:false, offsetY:tar_offset}, ease:'power2'});

		var hrefPageUrl = $(this).prop("href");
		hrefPageUrl = hrefPageUrl.split("#")[0];
		var currentUrl = location.href;
		currentUrl = currentUrl.split("#")[0];
		if( hrefPageUrl == currentUrl ){
			return false;
		}
	});
	$('#btt').on('click', function(){
		gsap.to(window, { duration:0.6, scrollTo:{y:0, autoKill:false}, ease:'power2'});
		return false;
	});
}


function expandFunc() {
	let exbtn = document.querySelectorAll('.exbtn');
	for (let i = 0; i < exbtn.length; i++) {
		exbtn[i].addEventListener('click',function(){
			let parent = this.parentElement;
			const result = parent.classList.contains('open');
			if (result) {
				parent.classList.remove('open');
				const exc = parent.querySelectorAll('.exc');
				gsap.utils.toArray(exc).forEach(ex => {
					gsap.to(ex,{
						height: 0,
						duration:0.3,
						ease: "power2.inOut"
					});
				});
			}else{
				parent.classList.add('open');
				const exc = parent.querySelectorAll('.exc');
				gsap.utils.toArray(exc).forEach(ex => {
					gsap.to(ex,{
						height:'auto',
						duration:0.3,
						ease: "power2.inOut",
					});
				});
			}
		})
	}
}


function videoSwitch() {
	var videoelm = document.getElementById('video');
	if(!videoelm) {
		return false;
	}
	var videosp = '_sp.';
	var videopc = '_pc.';
	var videoState = 1;
	if(window.matchMedia('(max-width:767px)').matches){
		if(videoState == 1){
			var srcname = videoelm.getAttribute('src').replace(videopc, videosp);
			videoelm.src = srcname;
			videoState = 0;	
		}
	}else{
		if(videoState == 0){
			var srcname = videoelm.getAttribute('src').replace(videosp, videopc);
			videoelm.src = srcname;
			videoState = 1;	
		}
	}
	videoelm.style.opacity = 1;
}


const kvSlider = async () => {
	await new Promise((resolve, reject) => {
		const swiperEl = document.querySelectorAll('#kvslider');
		swiperEl.forEach((swiperEl) => {
			const txset = document.querySelectorAll('.txset');
			const kvlink = document.querySelectorAll('#kvlinks a');
			const txsetChange = (index) => {
				txset.forEach((target) => {
					target.classList.remove('active');
				});
				txset[index].classList.add('active');
				kvlink.forEach((target) => {
					target.classList.remove('active');
				});
				kvlink[index].classList.add('active');
			};
			const slidepause = (index) => {
				swiper.autoplay.pause();
				swiper.slideTo(index);
				txsetChange(index);
			};
			const slideplay = () => {
				swiper.autoplay.start();
			};
			kvlink.forEach((target, index) => {
				target.addEventListener('mouseenter', function(){
					slidepause(index);
				});
				target.addEventListener('mouseleave', slideplay);
			});

			const swiper = new Swiper(swiperEl, {
				effect: 'fade',
				fadeEffect: {
					crossFade: true
				},
				slidesPerView: 1,
				speed: 800,
				spaceBetween: 0,
				loop: true,
				autoplay: {
					delay: 4000,
				},
				allowTouchMove: false,
				keyboard: {
					enabled: false,
					onlyInViewport: false,
				},
				on: {
					init: function(swiper){
						txsetChange(swiper.realIndex);
					},
					slideNextTransitionStart: function(swiper){
						txsetChange(swiper.realIndex);
					}
				}
			});
		});
		resolve();
	});
}


function topLogo() {
	const $kvlogo = document.getElementById('kv_logo');
	gsap.set($kvlogo, { opacity: 0 });
	const tl = gsap.timeline();
	gsap.to($kvlogo,{
		opacity:1,
		duration:2,
		ease: "power2.inOut",
	});
	$('.kvimg_link').on('mouseover', function(){
		gsap.to($kvlogo,{
			opacity:0,
			duration:0.4,
			ease: "power2.inOut",
		});
	})
	.on('mouseout', function(){
		gsap.to($kvlogo,{
			opacity:1,
			duration:0.4,
			ease: "power2.inOut",
		});
	});
}


function lead_btn() {
	$('#lead_btn').on('click', function(){
		$('#lead').toggleClass('entxt');
	});	
}


function singleSlider (){
	$('.swiper').each(function(){
		if(this.classList.contains('large')){
			var perview = 1;
		}else if(this.classList.contains('medium')){
			var perview = 1.6;
		}else if(this.classList.contains('medium2')){
			if(window.matchMedia('(max-width:767px)').matches){
				var perview = 1;
			}else{
				var perview = 1.2;
			}
		}else{
			var perview = 1;
		}
		const swiper = new Swiper(this, {
			slidesPerView: perview,
			speed: 600,
			spaceBetween: 0,
			centeredSlides: true,
			loop: true,
			keyboard: {
				enabled: true,
				onlyInViewport: false,
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		});
	});
}


function cursorFunc (){
	const cursorImg = document.querySelector(".cursorimg");
	const cursors = document.querySelectorAll(".cursor");
	if(cursors == null) return;
	cursors.forEach((cursor) => {
		let mouseon = false;
		cursor.addEventListener("mouseover", () => {
			document.body.classList.add("curon");
			mouseon = true;
		});
		cursor.addEventListener("mouseout", () => {
			document.body.classList.remove("curon");
			mouseon = false;
		});
		cursor.addEventListener("mousemove", (e) => {
			if(mouseon) {
				gsap.to(cursorImg,{
					x: e.clientX,
					y: e.clientY,
				});
			}
		});
	});
}


function offieLPFunc (){
	const $tabbtn = $('.tabbtn');
	const $roomtab_img = $('.roomtab_img');
	$tabbtn.on('click', function(){
		if( $(this).hasClass('active') ){
			return false;
		}
		$tabbtn.removeClass('active');
		$roomtab_img.removeClass('active');
		const id = $(this).attr('id');
		if( id == 'tabbtna' ){
			$('#tabbtna').addClass('active');
			$('#taba').addClass('active');
		}else{
			$('#tabbtnb').addClass('active');
			$('#tabb').addClass('active');
		}
		return false;
	});

	const $fbtn = $('.fbtn');
	const $f_img = $('.f_img');
	$fbtn.on('click', function(){
		if( $(this).hasClass('active') ){
			return false;
		}
		$fbtn.removeClass('active');
		$f_img.removeClass('active');
		const id = $(this).attr('id');
		if( id == 'fbtn_a' ){
			$('#fbtn_a').addClass('active');
			$('#f_a').addClass('active');
		}else{
			$('#fbtn_b').addClass('active');
			$('#f_b').addClass('active');
		}
		return false;
	});
}

function kvbannerFunc (){
	$('#kvbanner .close').on('click', function(){
		$('#kvbanner').addClass('close');
		return false;
	});
}

var PageEvents = {
	loadFunc: function(){
		navInit();
		body.addClass('load');
	},
	commonFunc: function(){
		this.pjaxReadyFunc();
		winH = $(window).height();
		winW = $(window).width();	
		body.removeClass('navwh navbk');
		setVH();
		elFunc('.el');
		nav_current();
		pageScroll();
		expandFunc();
		scrlPos();
		cursorFunc();
		// setTimeout(setVH, 200);
		// setTimeout(function(){
		// }, 200);
		OBSERVER.observeEvents(1);
	},
	pjaxReadyFunc: function(){
	},
	topFunc: async function(){
		body.addClass('top');
		await kvSlider();
		topLogo();
		lead_btn();
		postnavFunc();
		kvbannerFunc();
	},
	archiveFunc: function(){
		postnavFunc();
	},
	singleFunc: function(){
		singleSlider();
	},
	office_lpFunc: function(){
		body.addClass('haskv');
		offieLPFunc();
		kvbannerFunc();
	}
};

function pjaxSettings(){

	barba.use(barbaPrefetch);

	const replaceBogo = function(data){
		let target = '#wrapper';
		if(data){
			target = data.next.html;
		}
		const $newPageBogo = $(target).find('.bogo-language-switcher').html();
		body.find('#bogo').html($newPageBogo);
	}
	replaceBogo();

	const replaceHead = function(data){
		const head = document.head;
		const newPageRawHead = data.next.html.match(/<head[^>]*>([\s\S.]*)<\/head>/i)[0];
		const newPageHead = document.createElement('head');
		newPageHead.innerHTML = newPageRawHead;
		const removeHeadTags = [ 
			"meta[name='keywords']"
			,"meta[name='description']"
			,"meta[property^='og']"
			,"meta[name^='twitter']"
			,"meta[itemprop]"
			,"link[itemprop]"
			,"link[rel='prev']"
			,"link[rel='next']"
			,"link[rel='canonical']"
		].join(',');
		const headTags = head.querySelectorAll(removeHeadTags)
		for(let i = 0; i < headTags.length; i++ ){
			head.removeChild(headTags[i]);
		}
		const newHeadTags = newPageHead.querySelectorAll(removeHeadTags)
		for(let i = 0; i < newHeadTags.length; i++ ){
			head.appendChild(newHeadTags[i]);
		}
	}

	var eventDelete = function eventDelete(e) {
		if (e.currentTarget.href === window.location.href) {
			e.preventDefault()
			e.stopPropagation()
			return
		}
	}
	const links = Array.prototype.slice.call(document.querySelectorAll('a[href]'));
		links.forEach(function (link) {
			link.addEventListener('click', function (e) {
			eventDelete(e)
		}, false)
	})

	var gaPush = function gaPush(pagename) {
		if (typeof ga === 'function' && Barba.HistoryManager.history.length >= 1) {
			ga('send', 'pageview', pagename);
		}
		if (typeof gtag === 'function' && Barba.HistoryManager.history.length >= 1) { 
			// gtag('config', 'G-Xxxxxxxx', {'page_path': pagename}); 
		}
	}

	var preventSettings = function preventSettings(el) {
		let href = el.getAttribute('href');
		if(href == null){
			href = el.getAttribute('xlink:href');
		}
		if(el.classList.contains('nopj')){
			return true;
		}
		let site_url = location.protocol + '//' + location.host;
		if (!href.startsWith(site_url)) {
			el.setAttribute('target','_blank');
			return true;
		}
		let url = location.protocol + '//' + location.host + location.pathname;
		let extract_hash = href.replace(/#.*$/,"");
		if (href.startsWith(location.protocol + '//' + location.host)) {
			if (href.indexOf('#') > -1 && extract_hash != url ){
				return false;
			}
		}
		if (/\.(xlsx?|docx?|pptx?|pdf|jpe?g|png|gif|svg)/.test(href.toLowerCase())) {
			el.setAttribute('target','_blank');
			return true;
		}
	}

	async function scroll() {
		await new Promise((resolve, reject) => {
			if(location.hash){
				var anchor = document.querySelector( location.hash );
				if(anchor){
					var target = location.hash;
					gsap.to(window, { duration:0, scrollTo:target, ease:'power2', onComplete:function(){
						resolve();
					}});
				}else{
					gsap.to(window, { duration:0.6, scrollTo:0, ease:'power2', onComplete:function(){
						resolve();
					}});
				}
			}else{
				gsap.to(window, { duration:0.6, scrollTo:0, ease:'power2', onComplete:function(){
					resolve();
				}});
			}
		})
	}

	var $footerelm = $('footer')
	barba.init({
		prevent: function prevent(_ref) {
			var el = _ref.el;
			return preventSettings(el);
		},
		timeout: 10000,			
		transitions: [{
			name: 'transition',
			sync:true,
			leave:function(data) {
				const done = this.async();
				gsap.to('footer', {opacity:0, duration:0.2})
				gsap.to(data.current.container, {opacity:0, duration:0.2, onComplete:function(){
					ScrollSmootherKill();
					done();
				}});
			},
			enter:function(data) {
				const done = this.async();
				const $elm = $(data.next.container);
				$elm.css({'opacity':0});
				done();
			}
		}]
	})

	barba.hooks.beforeLeave(() => {
		body.removeClass('ready top inpage topready navopen navwh navbk postnav_fix scrldown curon haskv onevh');
		document.getElementById('nav').classList.remove('tnl');
	});

	barba.hooks.beforeEnter((data) => {
		replaceHead(data);
		replaceBogo(data);
		if( OBSERVER.handlers.length ){
			OBSERVER.clearEvents();
		}
		gaPush(location.pathname);
	});
	barba.hooks.after((data) => {
		async function callerFun(){
			await scroll();
			pageCheck();
			gsap.to('footer', {opacity:1, duration:0.6})
			gsap.to(data.next.container, {opacity:1, duration:0.6, onComplete:function(){
			}});
		}
		$('#container').imagesLoaded( function() {
			callerFun();
		});
	});
}

function pageCheck(){
	PageEvents.commonFunc();

	if( document.getElementById('top') ){
		PageEvents.topFunc();
	}else{
		body.addClass('inpage');
	}
	if( document.getElementById('archive') ){
		PageEvents.archiveFunc();
	}
	if( document.getElementById('single') ){
		PageEvents.singleFunc();
	}
	if( document.getElementById('office_lp') ){
		PageEvents.office_lpFunc();
	}
	body.addClass('ready');
}

function initialize(){
	window.addEventListener('DOMContentLoaded', function(){
		PageEvents.loadFunc();
		pageCheck();
		pjaxSettings();
	});
}

initialize();

})();
})( jQuery );

}